@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap&subset=cyrillic');

//$custom-border-radius: 16px;
//$custom-border-radius-2: 30px;

$custom-border-radius: 5px;
$custom-border-radius-2: 5px;

$custom-purple: #761FD7;
$custom-yellow: #FFDA57;
$custom-green: #55D185;
$custom-blue: #0075FF;
$custom-blue2: #2086FF;

@import '~bootstrap/scss/bootstrap';


@media screen and (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1340px;
  }
}

@media screen and (min-width: 1800px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1740px;
  }

  body {
    font-size: 18px;
  }
}

html {
  @media screen and (max-width: 767.98px) {
    overflow-x: auto;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #424242;
  overflow-x: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-x: hidden;
}

.modal-body {
  @media screen and (max-width: 767.98px) {
    padding: 5px;
  }
}

.spinner-border.custom-spinner {
  color: #0075FF !important;
}


.btn:focus, .btn:active {
  outline: none !important;
  //box-shadow: none !important;
}

input, select, textarea {
  border-radius: 0;
  border: 1px solid #dadada;
  box-shadow: inset 3px 3px 3px -3px #dadada;
}

input:focus, select:focus, textarea:focus, .react-datepicker-wrapper input:focus {
  border: 1px solid #8AB9CF;
  outline: none !important;
  box-shadow: none !important;
}

button:not(
.react-datepicker__navigation,
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next):focus {
  border: none !important;
  outline: none !important;
  //box-shadow: none !important;
}

input:active, select:active, textarea:active, .react-datepicker-wrapper input:active {
  border: 1px solid #8AB9CF;
  outline: none !important;
  box-shadow: none !important;
}

button:not(
.react-datepicker__navigation,
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next):active {
  border: none !important;
  outline: none !important;
  //box-shadow: none !important;
}

.react-datepicker__navigation:focus,
.react-datepicker__navigation--previous:focus,
.react-datepicker__navigation--next:focus,
.react-datepicker__day--selected {
  outline: none !important;
}

.react-datepicker__navigation:active,
.react-datepicker__navigation--previous:active,
.react-datepicker__navigation--next:active,
.react-datepicker__day--selected {
  outline: none !important;
}


::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1
}

:-moz-placeholder, ::-moz-placeholder {
  color: #ccc;
  opacity: 1
}

:-ms-input-placeholder {
  color: #ccc;
}

.form-control::-webkit-input-placeholder {
  color: #ccc;
}

.form-control:-moz-placeholder, ::-moz-placeholder {
  color: #ccc;
}

.form-control:-ms-input-placeholder {
  color: #ccc;
}


.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-wrapper input {
  height: calc(1.6em + 0.75rem);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: left;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input {
  height: calc(1.6em + 0.75rem);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: left;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.field-error {
  color: $red;
  font-size: 13px;
}


.Header {
  width: 100%;
  max-width: 2000px;
  margin: auto;
  height: 1500px;
  background: url('../images/header_banner.jpg') no-repeat;
  background-size: 100% auto;

  @media screen and (max-width: 767.98px) {
    background: url('../images/header_banner_mobile.jpg') no-repeat;
    background-size: 100% auto;
    margin-top: 50px;
  }

  .votu-text {
    padding-top: 65px;
    color: white;
    font-weight: bold;

    @media screen and (min-width: 1800px) {
      padding-top: 85px;
    }

    @media screen and (max-width: 1199.98px) {
      padding-top: 20px;
    }

    @media screen and (max-width: 991.98px) {
      padding-top: 10px;
      //margin-left: -20px;
    }

    @media screen and (max-width: 767.98px) {
      padding-top: 0px;
      color: $custom-purple;
      position: relative;
      //left: 50px;
      bottom: 35px;
    }

    @media screen and (max-width: 320.98px) {
      position: relative;
      //left: 20px;
      bottom: 35px;
    }

    .first {
      font-size: calc(24px * 1.75);

      @media screen and (max-width: 1199.98px) {
        font-size: calc(24px * 1.3);
      }

      @media screen and (max-width: 767.98px) {
        font-size: calc(24px * 1.3);
      }

      @media screen and (max-width: 320.98px) {
        font-size: calc(24px * 1.15);
      }
    }

    .second {
      font-size: calc(18px * 1.75);
      position: relative;
      top: -20px;

      @media screen and (max-width: 1199.98px) {
        font-size: calc(18px * 1.3);
      }

      @media screen and (max-width: 767.98px) {
        font-size: calc(18px * 1.3);
      }

      @media screen and (max-width: 320.98px) {
        font-size: calc(18px * 1.15);
      }
    }
  }

  .help-text {
    margin-top: 20px;
    color: white;
    font-weight: bold;
    font-size: 45px;
    line-height: 95%;

    @media screen and (min-width: 1800px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 1199.98px) {
      padding-top: 0px;
      font-size: 38px;
      margin-top: 0px;
    }

    @media screen and (max-width: 991.98px) {
      font-size: 28px;
    }

    @media screen and (max-width: 767.98px) {
      margin-top: 420px;
      font-size: 36px;
      line-height: 100%;
    }

    @media screen and (max-width: 575.98px) {
      margin-top: 300px;
      font-size: 30px;
    }

    @media screen and (max-width: 430.98px) {
      margin-top: 230px;
    }

    @media screen and (max-width: 400.98px) {
      margin-top: 200px;
    }

    @media screen and (max-width: 360.98px) {
      margin-top: 170px;
      font-size: 26px;
    }

    .yellow-text {
      color: $custom-yellow;
    }
  }

  .reassurance-block {
    color: white;
    margin-top: 50px;

    @media screen and (min-width: 1800px) {
      margin-top: 80px;
      font-size: 20px;
      padding-right: 200px;
    }

    @media screen and (max-width: 1199.98px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 991.98px) {
      line-height: 120%;
      font-size: 16px;
      margin-top: 15px;
    }

    @media screen and (max-width: 767.98px) {
      line-height: 150%;
      margin-top: 70px;
      font-size: 18px;
    }

    @media screen and (max-width: 575.98px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 320.98px) {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  .button-block {
    margin-top: 50px;

    @media screen and (min-width: 1800px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 1199.98px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 991.98px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 767.98px) {
      text-align: center;
      margin-top: 80px;
    }

    @media screen and (max-width: 575.98px) {
      text-align: center;
      margin-top: 30px;
    }

    @media screen and (max-width: 320.98px) {
      margin-top: 20px;
    }

    .custom-button {
      color: $custom-purple;
      border-color: white;
      border-radius: $custom-border-radius;
      box-shadow: 0px 6px 16px #B35DF4;
      font-size: 18px;
      font-weight: bold;
      padding: 10px 20px;
      background-color: white;
      width: 229px;
      height: 50px;

    }

    .custom-button:hover {
      background-color: darken(white, 2%);
      color: lighten($custom-purple, 10%);
    }
  }
}

.HeaderBusiness {
  position: relative;
  width: 100%;
  max-width: 2000px;
  margin: auto;
  //height: 690px;
  height: auto;
  background: url('../images/header_business.jpg') no-repeat, url('../images/header_business_min.jpg') no-repeat;
  //background-size: 100% auto;

  background-position: center;
  background-size: cover;

  @media screen and (max-width: 767.98px) {
    background-position: 60%;
  }

  @media screen and (max-width: 575.98px) {
    background-position: 70%;
  }

  .filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(9, 26, 43, 0.33);
  }

  .content-wrapper {
    position: relative;
  }

  .votu-text {
    padding-top: 65px;
    color: white;
    font-weight: bold;

    @media screen and (min-width: 1800px) {
      padding-top: 85px;
    }

    @media screen and (max-width: 767.98px) {
      text-align: center;
      padding-top: 40px;
    }

    @media screen and (max-width: 320.98px) {

    }

    .first {
      font-size: calc(24px * 1.75);

      @media screen and (max-width: 1199.98px) {

      }

      @media screen and (max-width: 767.98px) {
        font-size: calc(24px * 2.3);
      }

      @media screen and (max-width: 320.98px) {

      }
    }

    .second {
      font-size: calc(18px * 1.75);
      position: relative;
      top: -20px;

      @media screen and (max-width: 1199.98px) {

      }

      @media screen and (max-width: 767.98px) {
        font-size: calc(18px * 2.3);
        top: -30px;
      }

      @media screen and (max-width: 320.98px) {

      }
    }
  }

  .help-text {
    margin-top: 20px;
    color: white;
    font-weight: bold;
    font-size: 45px;
    line-height: 110%;

    @media screen and (min-width: 1800px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 1199.98px) {
      font-size: 38px;
    }

    @media screen and (max-width: 991.98px) {

    }

    @media screen and (max-width: 767.98px) {
      font-size: 34px;
      margin-top: 50px;
      text-align: center;
    }

    @media screen and (max-width: 575.98px) {

    }

    @media screen and (max-width: 360.98px) {
      font-size: 30px;
    }

    .yellow-text {
      color: $custom-yellow;
    }
  }

  .reassurance-block {
    color: white;
    margin-top: 50px;
    font-size: 20px;
    line-height: 120%;

    @media screen and (min-width: 1800px) {
      margin-top: 80px;
      font-size: 20px;
      padding-right: 200px;
    }

    @media screen and (max-width: 1199.98px) {

    }

    @media screen and (max-width: 991.98px) {

    }

    @media screen and (max-width: 767.98px) {
      text-align: center;
    }

    @media screen and (max-width: 575.98px) {

    }

    @media screen and (max-width: 360.98px) {
      font-size: 14px;
    }
  }

  .button-block {
    margin-top: 50px;
    padding-bottom: 80px;

    @media screen and (min-width: 1800px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 1199.98px) {

    }

    @media screen and (max-width: 991.98px) {

    }

    @media screen and (max-width: 767.98px) {
      text-align: center;
      margin-top: 60px;
      padding-bottom: 60px;
    }

    @media screen and (max-width: 575.98px) {
      text-align: center;

    }

    @media screen and (max-width: 320.98px) {

    }

    .custom-button {
      color: #424242;
      border-color: white;
      border-radius: $custom-border-radius;
      box-shadow: 0px 6px 9px rgba(4, 22, 35, 0.5);
      font-size: 18px;
      font-weight: bold;
      padding: 10px 20px;
      background-color: white;
      width: 229px;
      height: 50px;
    }

    .custom-button:hover {
      background-color: darken(white, 3%);
      //color: lighten($custom-purple, 10%);
    }
  }
}

.Goals {
  margin-top: 100px;

  @media screen and (max-width: 767.98px) {
    margin-top: 50px;
  }

  .goal-item {

    margin-bottom: 40px;

    .title {
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 500;

      @media screen and (max-width: 767.98px) {
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .body {
      @media screen and (max-width: 767.98px) {
        text-align: center;
      }
    }
  }
}

.BestAssistant {
  margin-top: 100px;

  @media screen and (max-width: 767.98px) {
    margin-top: 30px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 105%;
    margin-bottom: 50px;

    @media screen and (max-width: 767.98px) {
      text-align: center;
      font-size: 22px;
    }
  }

  .image-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    top: -80px;
    text-align: center;

    @media screen and (max-width: 991.98px) {
      width: 140%;
      left: -80px;
    }

    @media screen and (max-width: 767.98px) {
      width: 100%;
      left: 0px;
      top: 0px;
    }

    img {
      width: 95%;

      @media screen and (min-width: 1200.00px) {
        width: 80%;
      }

      @media screen and (min-width: 1800.00px) {
        width: 65%;
      }
    }
  }

  .icon-block {
    margin-top: 50px;

    @media screen and (max-width: 767.98px) {
      margin-top: 40px;
      text-align: center;
      margin-bottom: 25px;
    }

    .icon-img {
      margin-bottom: 20px;
    }
  }
}

.StepMap {

  --basic-item-left: 190px;
  --left-diff: 166px;
  --basic-line-left: calc(var(--basic-item-left) + 112px);
  --left-line-diff: 166px;

  @media screen and (max-width: 1199.98px) {
    --basic-item-left: 80px;
  }

  @media screen and (min-width: 1200.00px) {
    --basic-item-left: 280px;
  }

  @media screen and (min-width: 1800.00px) {
    --basic-item-left: 480px;
  }


  margin-top: 80px;
  margin-bottom: 100px;
  height: 539px;
  position: relative;
  //padding-left: 200px;

  background-color: #F7F9FB;
  //padding-bottom: 30px;

  @media screen and (max-width: 991.98px) {
    //height: 370px;
  }

  .main-image {
    width: 100%;
    position: relative;
    top: -100px;

    @media screen and (max-width: 1199.98px) {
      top: -100px;
    }

    @media screen and (max-width: 991.98px) {
      top: -88px;
    }

    @media screen and (max-width: 767.98px) {
      top: -130px;
    }
  }

  .step-map-person1 {
    position: absolute;
    left: -100px;
    top: -145px;
    transform: rotateY(180deg);
  }

  .step-map-person2 {
    position: absolute;
    left: -130px;
    bottom: -130px;
  }

  .step-map-person3 {
    position: absolute;
    right: -170px;
    bottom: -180px;
  }

  .title {
    text-align: right;
    font-weight: 600;
    font-size: 24px;
    padding: 20px 10px;

    @media screen and (min-width: 1800.00px) {
      font-size: 28px;
      padding: 30px 150px;
    }

    @media screen and (max-width: 767.98px) {
      text-align: left;
      font-size: 22px;
      line-height: 100%;
    }
  }

  .content-wrapper {
    position: relative;
  }

  .item-wrapper {
    position: absolute;
    display: flex;
  }

  .item-wrapper.item1 {
    left: var(--basic-item-left);
    top: 105px;
  }

  .item-wrapper.item2 {
    left: calc(var(--basic-item-left) + var(--left-diff));
    top: 311px;
  }

  .item-wrapper.item3 {
    //left: 690px;
    left: calc(var(--basic-item-left) + 2 * var(--left-diff));
    top: 105px;
  }

  .item-wrapper.item4 {
    //left: 856px;
    left: calc(var(--basic-item-left) + 3 * var(--left-diff));
    top: 311px;
  }

  .item-wrapper.item5 {
    //left: 1023px;
    left: calc(var(--basic-item-left) + 4 * var(--left-diff));
    top: 105px;
  }

  .text-wrapper {
    width: 150px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    line-height: 20px;

    @media screen and (min-width: 1800.00px) {
      width: 180px;
    }
  }


  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 18px;
  }

  .icon.icon1 {
    background-color: #8B7EEB;
  }

  .icon.icon2 {
    background-color: #FBC8D5;
  }

  .icon.icon3 {
    background-color: #65C3EC;
  }

  .icon.icon4 {
    background-color: #C9E96F;
  }

  .icon.icon5 {
    background-color: #F7955D;
  }

  .line {
    width: 4px;
    height: 200px;
    border-radius: 2px;
  }

  .line.line2 {
    background: linear-gradient(180deg, #8B7EEB 0%, #FBC8D4 100%);
    transform: rotate(-36.45deg);
  }

  .line.line3 {
    background: linear-gradient(360deg, #65C3EC 0.83%, #FBC8D5 100%);
    transform: rotate(-143.5deg);
  }

  .line.line4 {
    background: linear-gradient(180deg, #65C3EC 0%, #C9E96F 100%);
    transform: rotate(-36.45deg);
  }

  .line.line5 {
    background: linear-gradient(180deg, #C9E96F 0%, #F6955D 100%);
    transform: rotate(-143.5deg);
  }

  .line-wrapper {
    position: absolute;
  }

  .line-wrapper.item2 {
    left: var(--basic-line-left);
    top: 137px;
  }

  .line-wrapper.item3 {
    left: calc(var(--basic-line-left) + var(--left-line-diff));
    top: 137px;
  }

  .line-wrapper.item4 {
    left: calc(var(--basic-line-left) + 2 * var(--left-line-diff));
    top: 137px;
  }

  .line-wrapper.item5 {
    left: calc(var(--basic-line-left) + 3 * var(--left-line-diff));
    top: 137px;
  }


  .button-block {
    position: absolute;
    top: 510px;
    width: 100%;
    text-align: center;
  }

  .custom-button {
    color: #fff;
    background-color: $custom-green;
    border-color: $custom-green;
    box-shadow: 0px 6px 16px #6BBA89;
    border-radius: $custom-border-radius-2;

    width: 320px;
    height: 54px;

    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    @media screen and (max-width: 767.98px) {
      width: 280px;

      font-size: 20px;
      line-height: 24px;
    }

  }

  .custom-button:hover {
    background-color: darken($custom-green, 8%);
    border-color: darken($custom-green, 8%);
  }
}

.StepMapMobile {
  background-color: #F7F9FB;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 70px;
  padding-top: 15px;
  position: relative;

  .step-map-person1 {
    position: absolute;
    right: 0;
    top: -39px;
  }

  .step-map-person2 {
    position: absolute;
    //left: 10px;
    //top: 560px;
    left: -120px;
    bottom: -200px;
  }

  .step-map-person3 {
    position: absolute;
    //right: 0;
    //top: 900px;
    right: -130px;
    bottom: -110px;
  }

  .content-wrapper {
    margin-bottom: 60px;
  }

  .title {
    padding-right: 110px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .item-wrapper {
    text-align: center;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    width: 200px;
    line-height: 120%;
  }

  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 18px;
  }

  .icon.icon1 {
    background-color: #8B7EEB;
  }

  .icon.icon2 {
    background-color: #FBC8D5;
  }

  .icon.icon3 {
    background-color: #65C3EC;
  }

  .icon.icon4 {
    background-color: #C9E96F;
  }

  .icon.icon5 {
    background-color: #F7955D;
  }

  .line-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    width: 4px;
    height: 75px;
    border-radius: 2px 2px 0px 0px;
  }

  .line.line2 {
    background: linear-gradient(180deg, #8B7EEB 0%, #FBC8D4 100%);
  }

  .line.line3 {
    background: linear-gradient(360deg, #65C3EC 0.83%, #FBC8D5 100%);
  }

  .line.line4 {
    background: linear-gradient(180deg, #65C3EC 0%, #C9E96F 100%);
  }

  .line.line5 {
    background: linear-gradient(180deg, #C9E96F 0%, #F6955D 100%);
  }

  .button-block {
    position: relative;
    top: 26px;
    text-align: center;
  }

  .custom-button {
    color: #fff;
    background-color: $custom-green;
    border-color: $custom-green;
    box-shadow: 0px 6px 16px #6BBA89;
    border-radius: $custom-border-radius-2;

    width: 320px;
    height: 54px;

    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    @media screen and (max-width: 767.98px) {
      width: 280px;

      font-size: 20px;
      line-height: 24px;
    }

  }

  .custom-button:hover {
    background-color: darken($custom-green, 8%);
    border-color: darken($custom-green, 8%);
  }

}

.Video {
  position: relative;
  height: 550px;
  width: 100%;
  background: url('../images/video_bg.jpg') no-repeat center;

  @media screen and (max-width: 767.98px) {
    height: 450px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    @media screen and (min-width: 1440.00px) {
      margin: auto;
      left: calc(50% - 720px);
    }
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .button-wrapper {
    margin-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 767.98px) {
      margin-top: 100px;
    }
  }

  .play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 107px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);

    @media screen and (max-width: 767.98px) {
      width: 80px;
      height: 80px;
    }
  }

  .play-button:hover {
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }

  .title {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;

    @media screen and (min-width: 1800.00px) {
      font-size: 24px;
    }
  }

  .second-title {
    text-align: center;
    color: white;
    font-size: 28px;
    line-height: 120%;
    font-weight: bold;
    //margin-top: 30px;
    position: absolute;
    bottom: 130px;
    width: 100%;

    @media screen and (max-width: 767.98px) {
      position: absolute;
      bottom: 50px;
      font-size: 22px;
      padding: 0px 30px;
    }
  }

  .arrow-right {

    border-color: transparent black;
    border-style: solid;
    border-width: 20px 0px 20px 20px;
    height: 0px;
    width: 0px;
  }


}

.VideoModal {
  .videoModalContent {
    width: 90vw;

    @media screen and (max-width: 767.98px) {
      width: 96vw;
    }
  }
}

.SliderWrapper {
  position: relative;
  top: 460px;

  @media screen and (max-width: 767.98px) {
    position: relative;
    top: 0px;
  }

  .title {
    text-align: center;
    color: white;
    font-size: 28px;
    line-height: 120%;
    font-weight: bold;
  }
}

.Slider {
  position: relative;
  z-index: 3;
  margin-top: 30px;
  height: 310px;
  background-color: white;
  box-shadow: 0px 3.3749px 0.362125px rgba(0, 0, 0, 0.00118505), 0px 6.71329px 3.29386px rgba(0, 0, 0, 0.00328239), 0px 10.1116px 9.47409px rgba(0, 0, 0, 0.00667679), 0px 13.8336px 20.0217px rgba(0, 0, 0, 0.0119635), 0px 18.7128px 37.0401px rgba(0, 0, 0, 0.0201901), 0px 27.7501px 65.5615px rgba(0, 0, 0, 0.0336213), 0px 46px 130px rgba(0, 0, 0, 0.23);
  border-radius: 3px;


  @media screen and (min-width: 1200.00px) {
    width: 80%;
    margin: auto;
  }

  @media screen and (min-width: 1800.00px) {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 767.98px) {
    height: 350px;
  }

  @media screen and (max-width: 575.98px) {
    height: 400px;
  }

  @media screen and (max-width: 380.00px) {
    height: 430px;
  }

  @media screen and (max-width: 350.00px) {
    height: 450px;
  }

  .comment-text {
    font-size: 14px;

    @media screen and (min-width: 1800.00px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767.98px) {
    margin-top: 0px;
  }

  .carousel-item {
    padding: 20px 15px;
  }

  .comment-author {
    font-size: 18px;
    text-align: center;
    margin-top: 40px;

    @media screen and (min-width: 1800.00px) {
      font-size: 20px;
    }
  }

  .carousel-indicators li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #424242;
    margin-right: 10px;
    margin-left: 10px;
  }

  .carousel-control-prev, .carousel-control-next {
    position: absolute;
    bottom: 15px;
    top: auto;
    width: 30px;
    height: 30px;
  }

  .carousel-control-prev {
    left: 30px;
  }

  .carousel-control-next {
    right: 30px;
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 30px;
    height: 30px;
  }

  .carousel-control-prev-icon {
    background: url('../images/slider_arrow_right.png') no-repeat;
    transform: rotate(-180deg);
  }

  .carousel-control-next-icon {
    background: url('../images/slider_arrow_right.png') no-repeat;
  }
}

.LetterToUs {
  position: relative;
  height: 550px;
  background: url('../images/letter_to_us_bg.jpg') no-repeat;
  background-size: cover;
  margin-top: 180px;
  margin-bottom: 70px;

  @media screen and (min-width: 1200.00px) {
    margin-top: 150px;
  }

  @media screen and (max-width: 767.98px) {
    position: relative;
    top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 139, 255, 0.85);
    box-shadow: 0px 3.3749px 0.362125px rgba(0, 0, 0, 0.00118505), 0px 6.71329px 3.29386px rgba(0, 0, 0, 0.00328239), 0px 10.1116px 9.47409px rgba(0, 0, 0, 0.00667679), 0px 13.8336px 20.0217px rgba(0, 0, 0, 0.0119635), 0px 18.7128px 37.0401px rgba(0, 0, 0, 0.0201901), 0px 27.7501px 65.5615px rgba(0, 0, 0, 0.0336213), 0px 46px 130px rgba(0, 0, 0, 0.06);
  }

  .content-wrapper {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 767.98px) {
      top: 100px;
    }
  }

  .title1 {
    color: white;
    font-size: 36px;
    font-weight: bold;

    @media screen and (max-width: 767.98px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .title2 {
    color: white;
    font-size: 24px;
    font-weight: bold;

    @media screen and (max-width: 767.98px) {
      margin-top: 50px;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .button-wrapper {
    margin-top: 50px;

    @media screen and (max-width: 767.98px) {
      margin-top: 100px;
    }
  }

  .custom-button {
    color: #fff;
    background-color: $custom-green;
    border-color: $custom-green;
    box-shadow: 0px 6px 16px rgba(39, 82, 145, 0.5);
    border-radius: $custom-border-radius-2;

    width: 320px;
    height: 54px;

    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    @media screen and (max-width: 767.98px) {
      width: 280px;

      font-size: 20px;
      line-height: 24px;
    }
  }

  .custom-button:hover {
    background-color: darken($custom-green, 8%);
    border-color: darken($custom-green, 8%);
  }

  .text-block {
    margin-top: 50px;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
}

.Footer {
  padding: 25px;
  background-color: #2C2E36;
  color: #cccccc;
  font-size: 14px;

  .trademark-box {
    margin-top: 20px;
    text-align: right;
    color: #f2f2f2;

    @media screen and (max-width: 767.98px) {
      text-align: center;
      margin-top: 30px;
    }
  }

  .social-title {
    text-align: right;

    @media screen and (max-width: 767.98px) {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 25px;
    }
  }

  .social-box {
    @media screen and (max-width: 767.98px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .social-button {
    position: relative;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #e6e6e6;
    margin-right: 15px;

    @media screen and (max-width: 991.98px) {
      margin-right: 6px;
    }

    @media screen and (max-width: 767.98px) {
      margin-right: 0px;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .social-button:hover path,
  .social-button:active path {
    fill: white;
  }

  .social-button.facebook-icon:hover,
  .social-button.facebook-icon:active {
    background-color: #3B5998;
  }

  .social-button.instagram-icon:hover,
  .social-button.instagram-icon:active {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  }

  .social-button.youtube-icon:hover,
  .social-button.youtube-icon:active {
    background-color: #c4302b;
  }

  .modal-link:hover {
    cursor: pointer;
    color: white;
  }

  .modal-link-wrapper {
    @media screen and (max-width: 767.98px) {
      text-align: center !important;
      margin-bottom: 17px;
    }
  }
}

.MainModal, .OrderModal {

  .modal-content {
    //position: relative;
    width: 80vw;
    max-width: 1440px;
    height: 90vh;
    padding-bottom: 90px;

    @media screen and (max-width: 767.98px) {
      height: 98vh;
      width: 98vw;
      padding-bottom: 55px;
    }
  }

  //.img-right {
  //  position: absolute;
  //  bottom: -50px;
  //  right: -50px;
  //  width: 100px;
  //  height: auto;
  //}

  .modal-header {
    border-bottom: none;

    @media screen and (max-width: 767.98px) {
      padding: 10px 10px 15px 10px;
    }
  }

  .modal-body {
    top: -15px;
  }

  .button-block {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 767.98px) {
      bottom: 10px;
    }
  }

  .customButtonNext {
    background: #0075FF;
    box-shadow: 0px 6px 16px rgba(32, 134, 255, 0.5);
    border-radius: 3px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    width: 120px;
    height: 50px;
  }

  .customButtonNext.step3 {
    width: 200px;
  }

  .customButtonNext:disabled {
    background: #e6e6e6;
    color: #A4A4A4;
    box-shadow: none;
  }

  .customButtonNext:disabled:hover {
    cursor: auto;
  }

  .customButtonNext:not([disabled]):hover {
    background-color: darken(#0075FF, 8%);
    border-color: darken(#0075FF, 8%);
  }

  .BackButton:hover {
    cursor: pointer;
  }

  .BackButton:hover path {
    stroke: $custom-blue;
    stroke-opacity: 1;
    stroke-width: 2.2;
  }

}

.OrderStatusModal {

  .modal-content {
    width: 80vw;
    max-width: 1440px;
    height: 90vh;

    @media screen and (max-width: 767.98px) {
      height: 98vh;
      width: 98vw;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  text-align: center;

  .success-image {
    width: 220px;
  }

  .greeting {
    margin-top: 30px;
    font-size: 28px;
    font-weight: 500;
    color: $custom-blue;
  }

  .payment-success {
    margin-top: 20px;
  }

  .text {
    margin-top: 25px;
  }

  .button-block {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .custom-button {
    color: #fff;
    background-color: $custom-green;
    border-color: $custom-green;
    box-shadow: 0px 6px 16px #6BBA89;
    border-radius: $custom-border-radius-2;

    width: 320px;
    height: 54px;

    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    @media screen and (max-width: 767.98px) {
      width: 280px;

      font-size: 20px;
      line-height: 24px;
    }

  }

  .custom-button:hover {
    background-color: darken($custom-green, 8%);
    border-color: darken($custom-green, 8%);
  }
}

.StepBlock {

  position: sticky;
  top: 0;

  @media screen and (max-width: 767.98px) {

  }

  .image-wrapper {
    text-align: right;

    @media screen and (max-width: 767.98px) {
      text-align: center;
    }

    img {

      @media screen and (max-width: 767.98px) {
        width: 80px;
        position: relative;
        top: -40px;
      }
    }
  }


  .step-block-wrapper {
    margin-top: 30px;
    padding: 20px;
    color: #C4C4C4;
    transition: left 0.3s linear;

    @media screen and (min-width: 1800.00px) {
      padding-left: 80px;
    }

    @media screen and (max-width: 991.98px) {
      padding: 0px;
      padding-bottom: 15px;
      margin-top: 0px;
      width: 1500px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 1px 5px 5px rgba(238, 238, 238, 0.5);

      position: relative;
      top: -20px;

      line-height: 120%;

    }
  }

  .step-block-wrapper.step1 {
    @media screen and (max-width: 991.98px) {
      left: 0px;
    }
  }

  .step-block-wrapper.step2 {
    @media screen and (max-width: 991.98px) {
      left: -180px;
    }
  }

  .step-block-wrapper.step3 {
    @media screen and (max-width: 991.98px) {
      left: -350px;
    }
  }

  .step-block-wrapper.step4 {
    @media screen and (max-width: 991.98px) {
      left: -555px;
    }
  }

  .step-item {
    @media screen and (max-width: 991.98px) {
      display: inline-block;
    }
  }

  .step-item:first-child {
    @media screen and (max-width: 991.98px) {
      position: relative;
      bottom: -9px;
    }
  }

  .step-item.active {
    color: #0075FF;
  }

  .step-line {
    position: relative;
    left: 50px;
    width: 2px;
    height: 30px;
    background-color: #C4C4C4;
    margin-top: 20px;
    margin-bottom: 5px;

    @media screen and (max-width: 991.98px) {
      display: inline-block;
      left: 0px;
      bottom: 5px;
      width: 20px;
      height: 2px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .step-line.active {
    background-color: #0075FF;
  }
}

.MainModalBlockChoose {

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;

  @media screen and (max-width: 767.98px) {
    //padding-left: 0px;
    //padding-right: 0px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 767.98px) {
      font-size: 18px;
      text-align: center;
    }
  }

  .issues-block {
    margin-top: 20px;
  }

  .form-check-label {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .form-check-label:hover {
    cursor: pointer;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 10px #f2f2f2;
  }

  .form-check-label.active {
    color: #0075FF;
  }

}

.MainModalBlockAuth {

  margin-left: 100px;

  @media screen and (max-width: 991.98px) {
    margin-left: 0px;
    padding: 15px;
  }

  .title {
    margin-top: 150px;
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 500;

    @media screen and (max-width: 991.98px) {
      margin-top: 50px;
      margin-bottom: 20px;
      font-size: 20px;
      text-align: center;
    }
  }

  input[name=promoCode], .content {
    width: 400px;

    @media screen and (min-width: 768px) and (max-width: 991.98px) {
      margin: auto;
    }

    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
  }

  .content {
    font-size: 14px;
    margin-top: 20px;
  }

  .item {
    margin-top: 15px;
  }

  .text-email {
    color: $custom-blue;
    font-weight: 500;
  }

  .text-error {
    color: $red;
    font-size: 14px;
    margin-top: 5px;
  }

  .buy-license-wrapper {
    margin-top: 30px;
    font-size: 14px;
    width: 400px;

    @media screen and (min-width: 768px) and (max-width: 991.98px) {
      margin: auto;
      margin-top: 30px;
    }
  }

  .buy-license-question {

  }

  .buy-license {
    color: $custom-blue;
    text-decoration: underline;
    font-weight: 600;
    margin-top: 5px;

  }

  .buy-license:hover {
    cursor: pointer;
  }
}

.license-agreement-link {
  color: $custom-blue;
  text-decoration: underline;
  height: 24px;
}

.license-agreement-link:hover {
  color: darken($custom-blue, 8%);
}

.hover-label:hover {
  cursor: pointer;
}

.MainModalBlockForm {

  label {
    font-size: 15px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 767.98px) {

  }

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;

  .issue-title {
    font-size: 20px;
    font-weight: 500;
  }

  .form-check-label:hover {
    cursor: pointer;
  }

  .issue-text {
    font-size: 14px;
    color: #0075FF;
    margin-top: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #B9B9B9;
  }

  .issue-description {
    margin-top: 30px;
    font-size: 14px;

    h4 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .text {
    margin-top: 30px;
    font-size: 14px;
  }

  .form-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.MainModalBlockFinish {
  text-align: center;

  .title {
    margin-top: 30px;
    color: $custom-blue2;
    font-weight: 600;
    font-size: 40px;
  }

  .text, .text-second {
    margin-top: 30px;
    font-size: 15px;
    padding: 0px 200px;

    @media screen and (max-width: 1199.98px) {
      padding: 0px 100px;
    }

    @media screen and (max-width: 991.98px) {
      padding: 0px 50px;
    }

    @media screen and (max-width: 767.98px) {
      padding: 0px 15px;
      margin-top: 20px;
    }
  }

  .text-email {
    font-size: 15px;
    font-weight: 600;
    color: $custom-blue2;
    margin-left: 7px;
  }

  .thanks {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    color: $custom-blue2;

    @media screen and (max-width: 767.98px) {
      margin-top: 30px;
    }
  }

  .success-image {
    max-width: 180px;

    @media screen and (max-width: 767.98px) {
      max-width: 135px;
      margin-top: 10px;
    }
  }
}

.LicenseAgreementModal,
.PrivacyPolicyModal,
.DisclaimerModal,
.MailConsentModal {

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }

  .modal-content {
    width: 80vw;
    max-width: 1440px;
    height: 90vh;

    @media screen and (max-width: 767.98px) {
      height: 98vh;
      width: 98vw;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0px 15px 15px 15px;
  }

  .license-header {
    font-size: 20px;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 767.98px) {
      font-size: 20px;
    }
  }

  .license-content {
    margin-top: 40px;
    font-size: 15px;

    h3 {
      font-size: 20px;
      margin-bottom: 30px;
      text-align: center;

      @media screen and (max-width: 767.98px) {
        font-size: 18px;
      }
    }
  }

  .license-button-block {
    text-align: center;
    margin-top: 30px;
  }
}

.okButtonModal {
  background: $custom-blue;
  box-shadow: 0px 6px 16px rgba(32, 134, 255, 0.5);
  border-radius: 3px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  width: 120px;
  height: 50px;
  margin-bottom: 20px;
}

.okButtonModal:focus {
  box-shadow: 0px 6px 16px rgba(32, 134, 255, 0.5);
}

.okButtonModal:hover {
  color: white;
  background-color: darken($custom-blue, 8%);
  border-color: darken($custom-blue, 8%);
}

.FeedBackModal {

  .modal-content {
    width: 50vw;

    @media screen and (max-width: 1199.98px) {
      width: 70vw;
    }

    @media screen and (max-width: 767.98px) {
      width: 96vw;
    }
  }

  .feedback-header {
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0px 25px 25px 25px;

    @media screen and (max-width: 767.98px) {
      padding: 0px 15px 15px 15px;
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;
  }

  .okButtonModal {
    width: 150px;
    margin-bottom: 10px;
  }

  .FeedBackSuccess {
    text-align: center;

    .success-image {
      width: 200px;
      max-width: 80%;
    }

    .title {
      margin-top: 30px;
      color: $custom-blue2;
      font-weight: 600;
      font-size: 40px;
    }

    .text {
      margin-top: 50px;
      font-size: 16px;
      padding: 0px 30px;

      @media screen and (max-width: 575.98px) {
        padding: 0px 10px;
      }
    }

    .thanks {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 20px;
      font-weight: 600;
      color: $custom-blue2;
    }
  }

}

.OrderModal {

  .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .customButtonNext.step {
    width: 250px;
  }
}

.OrderModalLicense {

  margin-left: 20px;

  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .license-duration {
    margin-left: 5px;
  }

  .license-price {
    margin-left: 10px;
  }

  .form-check {
    margin-bottom: 15px;
  }

  .form-check-label.active {
    color: #0075FF;
    font-weight: 500;
  }

  .form-check-label:hover {
    cursor: pointer;
  }

  .form-check-input {
    //position: relative;
    //right: 5px;
    //bottom: 1px;
  }
}

.OrderModalForm {

  .selected-license-wrapper {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;

    .title {

    }

    .license-text {
      margin-left: 10px;
      color: $custom-blue;
    }
  }

  .title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .license-send {
    margin-top: 20px;
    font-weight: 500;
  }
}

.Payment {
  .content-wrapper {
    margin-top: 200px;
    text-align: center;
  }

  .title {

  }
}

